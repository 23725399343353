body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.clickable-row {
  cursor: pointer;
}
.clickable-row > td {
  background-color: #e6f4ff !important;
}
.clickable-row:hover > td {
  background-color: #e6f4ff !important;
}

.no-background > td {
  background-color: transparent !important;
}
.no-background:hover > td {
  background-color: transparent !important;
}

.warning-row > td {
  background-color: #fff2f0 !important;
}
.warning-row:hover > td {
  background-color: #fff2f0 !important;
}

.warning-row.clickable-row > td {
  background-color: #ffccc7 !important;
}
.warning-row.clickable-row:hover > td {
  background-color: #ffccc7 !important;
}

.location-icon {
  border: 0px !important;
  background: transparent !important;
  margin-top: -40px !important;
}
